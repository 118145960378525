import * as React from 'react';
import { getSanityPreviewClient } from '../utils/sanity';

const defaultContext = {
  isPreview: false,
  setIsPreview: '',
  isLoadingPreview: false,
  setIsLoadingPreview: '',
  documentId: false,
  setDocumentId: ''
};

const PreviewContext = React.createContext(defaultContext);

const PreviewProvider = ({children}) => {
  
  const url = new URL(window.location.href);
  const [isPreview, setIsPreview] = React.useState(url.pathname.startsWith('/_preview'));
  const [isLoadingPreview, setIsLoadingPreview] = React.useState(false);
  const [documentId, setDocumentId] = React.useState(false);

  React.useEffect(() => {
    const getSanityUserData = async () => {
      const response = await getSanityPreviewClient()
      .config({withCredentials: true})
      .users.getById('me');
      setIsLoadingPreview(false);
      return response;
    }

    if(isPreview) {
      setIsLoadingPreview(true);
      const urlSearchParams = new URLSearchParams(window.location.search)
      setDocumentId(urlSearchParams.get("_id"));
      getSanityUserData();
    }
  }, [isPreview]);

  return (
    <PreviewContext.Provider
      value={{
        isPreview,
        setIsPreview,
        isLoadingPreview,
        setIsLoadingPreview,
        documentId,
        setDocumentId
      }}>
      {isLoadingPreview 
        ? <div>Loading...</div>
        : children}
    </PreviewContext.Provider>
  )
}

export default PreviewProvider;
export {PreviewContext};