import './src/styles/global.css'
import * as React from 'react';
import SanityPreviewConnectorProvider from './src/components/preview/sanityPreviewConnector';
import PreviewProvider from './src/providers/previewProvider';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';
import { ScrollTrigger } from 'gsap/all';
import { ScrollSmoother } from 'gsap/all';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import SplitText from 'gsap/SplitText';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';

if(typeof window !== undefined) {
  gsap.registerPlugin(useGSAP, SplitText, DrawSVGPlugin, ScrollTrigger, ScrollSmoother, ScrollToPlugin);
}

export const wrapRootElement = ({element}) => {
  return (
    <PreviewProvider>
      <SanityPreviewConnectorProvider>
        {element}
      </SanityPreviewConnectorProvider>
    </PreviewProvider>
  )
}